import React, { Component } from 'react';
import "./HomeComponent.css";
import { API } from 'aws-amplify';
import { Table } from 'reactstrap';
import LinkTableRow from './LinkTableRowComponent';
import { ClipLoader } from 'react-spinners';
import withSizes from 'react-sizes'
import LinkIdTargetCard from './LinkIdTargetCard';
import InfiniteScroll from "react-infinite-scroll-component";
import { apiGet } from '../../libs/callAPI';



class MainView extends Component {
  constructor(props) {
      super(props);
      this.state = {
        links: [],
        isInitiallyLoading: true,
        hasMore: null,
        lastEvaluatedKey: null,
      };
    }
    
  componentDidMount(){
    this.getData("link", "/link", null);
  }

  getData(apiName, apiPath, lastEvaluatedKey){
    apiGet(apiName, apiPath, lastEvaluatedKey)
    .then((response) => {
      console.log("Antwort: ", response);
      if(response.Error){
        console.log("Fehler: ", response.Error);
        this.setState({isInitiallyLoading: false});
      }else {
      if(response.LastEvaluatedKey){
        //es wurden nicht alle Daten geschickt
        this.setState({
          isInitiallyLoading: false,
          links: this.state.links.concat(response.Links),
          lastEvaluatedKey: response.LastEvaluatedKey,
          hasMore: true
        })
      } else {
        //es wurden alle Daten geschickt
        this.setState({
          isInitiallyLoading: false,
          links: this.state.links.concat(response.Links),
          hasMore: false
        })
      }
    }
    })
    .catch((error) => {
      console.log("Error: ", error);
      this.setState({isInitiallyLoading: false});
    })
  }

  getMoreData(){
    this.getData("link", "/link", this.state.lastEvaluatedKey)
  }

  //aktualisiert das Array in dem alle Links vorhanden sind. Wird aufgerufen, wenn erfolgreich per API ein Eintrag aktualisiert wurde
  updateDataFromApiResponse = (updatedRow) => {
    const oldArray = this.state.links;
    const updatedArray = oldArray.slice(); //kopiert
    const indexToBereplaced = updatedArray.findIndex(item => item.linkId === updatedRow.linkId);
    updatedArray[indexToBereplaced] = updatedRow;
    this.setState({links: updatedArray})
  }
  
  renderMainViewDesktop(){
    return(
      <InfiniteScroll
      dataLength={this.state.links.length}
      next={this.getMoreData.bind(this)}
      hasMore={this.state.hasMore}
      loader={this.renderLoader()}
      >
        <Table borderless>
          <thead>
            <tr>
              <th style={{width: '10%'}}>#</th>
              <th style={{width: '35%'}}>QR-Code</th>
              <th style={{width: '35%'}}>Linkziel</th>
              <th style={{width: '20%'}}>Aktion</th>
            </tr>
          </thead>
          <tbody>
            {this.state.links.map((link, index) => 
            <LinkTableRow key={index} index={index} link={link}
            updateLinkList={this.updateDataFromApiResponse} />
            )}
          </tbody>
        </Table>
      </InfiniteScroll>
    )
  }

  renderMainViewMobile(){
    return(
      <InfiniteScroll
      dataLength={this.state.links.length}
      next={this.getMoreData.bind(this)}
      hasMore={this.state.hasMore}
      loader={this.renderLoader()}
      >
      {this.state.links.map((link, index) => 
      <LinkIdTargetCard key={index} link={link} updateLinkList={this.updateDataFromApiResponse} />
      )}
      </InfiniteScroll>
    )
  }

  renderLoader(){
    return (
      <div className="loader-wrapper">
        <ClipLoader
        sizeUnit={"px"}
        size={50}
        color={'#28a745'}
        />
    </div>
    )
  }

  render(){
    if(this.state.isInitiallyLoading === false){
      return (
        <div className="home">
          <br />
          {this.props.isMobile ? this.renderMainViewMobile() : this.renderMainViewDesktop()}
        </div>
        );
      } else {
        return this.renderLoader()
    }
  }
}

//Ab einer Bildschirmbreite von unter 576px wird isMobile = true und der Inhalt rendert dementsprechend unterschiedlich
const mapSizesToProps = ({ width }) => ({
  isMobile: width < 576,
})

export default withSizes(mapSizesToProps)(MainView)