import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import DatenschutzMainComponent from '../../components/Datenschutz/DatenschutzMainComponent';



export default class DatenschutzScreen extends Component {

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render(){
        return(
            <Container>
                <Row>
                    <Col>
                        <DatenschutzMainComponent {... this.props}/>
                    </Col>
                </Row>
            </Container>
        )
    }
}