import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Footer.css'

export default class Footer extends Component{

    render(){
        return(
            <Row className="row--footer">
                <Col className="text-center">
                <footer>
                    <Link to="/about">Über 8 QR</Link> | <Link to="/impressum">Impressum</Link> | <Link to="/agb">AGB</Link> | <Link to ="/datenschutz">Datenschutz</Link>
                </footer>
                </Col>
            </Row>
        )
    }
}
