import { I18n } from 'aws-amplify';


export function translate(ErrorObject){
    switch(ErrorObject.message){
        case "Incorrect username or password.":
            return I18n.get("Incorrect username or password.")
        case "User does not exist.":
            return I18n.get("User does not exist.")
        case "Invalid verification code provided, please try again.":
            return I18n.get("Invalid verification code provided, please try again.")
        case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
            return I18n.get("1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6")
        case "An account with the given email already exists.":
            return I18n.get("An account with the given email already exists.")
        case "User is not confirmed.":
            return I18n.get("User is not confirmed.")
        default:
            return ErrorObject.message;
    }
}