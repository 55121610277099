import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ImpressumMainComponent from '../../components/Impressum/ImpressumMainComponent';



export default class ImpressumScreen extends Component {

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render(){
        return(
            <Container>
                <Row>
                    <Col>
                        <ImpressumMainComponent {... this.props}/>
                    </Col>
                </Row>
            </Container>
        )
    }
}