import React from 'react';
import { Button } from 'reactstrap';
import { FaSync} from "react-icons/fa"
import "./LoaderButton.css"


export default ({
  color,
    isLoading,
    text,
    loadingText,
    className = "",
    disabled = false,
    ...props
  }) =>
    <Button
    outline
    color={color}
      className={`LoaderButton ${className}`}
      disabled={disabled || isLoading}
      {...props}
    >
      {!isLoading ? text : loadingText}
      {isLoading &&
      <FaSync className="spinning-icon" />}
    </Button>;