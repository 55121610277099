import React, { Component, Fragment} from 'react';
import { Input, Button } from 'reactstrap'
import { API } from 'aws-amplify';
import LoaderButton from '../UI/LoaderButton';



export default class LinkTableRow extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isChangeMode: false,
            linkTarget: '',
            isUpdating: false
        }
    }

    componentDidMount(){
        this.setState({linkTarget: this.props.link.linkTarget})
    }
    
    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
      };

    toggleChangeTargetMode(){
        this.setState({isChangeMode: !this.state.isChangeMode})
    }

    saveChangeHandler(){
        const oldLinkTarget = this.props.link.linkTarget;
        if(this.state.linkTarget.length > 0 && !(this.state.linkTarget === oldLinkTarget)){
            this.updateData();
        } else {
            this.toggleChangeTargetMode()
        }
    }

    updateData() {
        this.setState({isUpdating: true});
        const linkId = this.props.link.linkId;
        const createdAt = this.props.link.createdAt;
        const linkTarget = this.state.linkTarget;
        console.log("UPDATE ID: ", linkId);
        API.put("link", `/link/${linkId}`, {
            body: {
                linkTarget: linkTarget,
                createdAt: createdAt
                }
            })
            .then((response) => {
            this.setState({isUpdating: false});
            console.log("Antwort: ", response);
            this.updateLinksInParent(response.aktualisierterEintrag);
            this.toggleChangeTargetMode();
        })
        .catch((error) => {
            this.setState({isUpdating: false});
            console.log("Error: ", error);
            })
        }
    
    updateLinksInParent(updatedRow) {
        this.props.updateLinkList(updatedRow);
    }

    renderPlainRow(){
        return(
            <tr>
                <th scope="row">{this.props.index+1}</th>
                <td>www.8qr.eu/<b>{this.props.link.linkId}</b></td>
                <td>{this.state.linkTarget}</td>
                <td><Button onClick={this.toggleChangeTargetMode.bind(this)} outline color="primary">Linkziel ändern</Button></td>
            </tr>
            )
    }

    renderChangeTargetRow(){
        return(
            <tr>
                <th scope="row">{this.props.index+1}</th>
                <td>www.8qr.eu/<b>{this.props.link.linkId}</b></td>
                <td><Input type="text" id="linkTarget" onChange={this.handleChange} value={this.state.linkTarget} /></td>
                <td><LoaderButton onClick={this.saveChangeHandler.bind(this)} color="success"
                    text="Änderung speichern" loadingText="wird gespeichert.." isLoading={this.state.isUpdating} /></td>
            </tr>
            )
    }

    render(){
        return(
            <Fragment>
                {this.state.isChangeMode ? this.renderChangeTargetRow() : this.renderPlainRow()}
            </Fragment>
            )
    }
    
}