import React, { Component } from "react";
import * as Sentry from '@sentry/browser';
import { Button } from 'reactstrap';


class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: false,
        info: null,
      };
    }
  
    componentDidCatch(error, info) {
      //Es ist ein Fehler in den Children aufgetreten
      this.setState({
        error: error,
        info: info,
      });
    }
  
    render() {
      if(this.state.error) {
        // render die Fallback Ui
        return (
          <div className="lander" style={{padding: '80px 0', textAlign: 'center'}}>
            <h5>Sorry. Ein interner Fehler ist aufgetreten</h5>
            <Button color="primary" onClick={() => Sentry.showReportDialog()}>Rückmeldung senden</Button>
          </div>
        );
      }
      // No errors were thrown. As you were.
      return this.props.children;
    }
  }

  export default ErrorBoundary;

