import React, { Component } from 'react';
import "./LoginScreen.css";
import LoginMainComponent from '../../components/Login/LoginMainComponent';
import CompleteNewPasswordMainComponent from '../../components/CompleteNewPassword/CompleteNewPasswordMainComponent';

export default class LoginScreen extends Component{
    constructor(props) {
      super(props);
      this.state = {
        user: null
      }
    }

    showCompletePasswordForm = (user) => {
      console.log("USER KENNWORT ÄNDERN ANZEIGEN MIT USER: ", user);
      this.setState({user: user})
    } 
  
    render(){
      return(
        <div className="Login-forms">
          {this.state.user ? 
            <CompleteNewPasswordMainComponent user={this.state.user} {... this.props} /> : 
            <LoginMainComponent {... this.props} userNeedsToCompletePassword={this.showCompletePasswordForm}/>}
        </div>
        )
    }
}

