import React, { Component, Fragment} from 'react';
import { Input, Button, Card, CardBody, CardTitle, CardSubtitle, ListGroupItem, CardText, Row, Col} from 'reactstrap'
import { API } from 'aws-amplify';
import LoaderButton from '../UI/LoaderButton';
import { apiPut } from '../../libs/callAPI';



export default class LinkIdTargetCard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isChangeMode: false,
            linkTarget: '',
            isUpdating: false
        }
    }

    componentDidMount(){
        this.setState({linkTarget: this.props.link.linkTarget})
    }
    
    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
      };

    toggleChangeTargetMode(){
        this.setState({isChangeMode: !this.state.isChangeMode})
    }

    saveChangeHandler(){
        const oldLinkTarget = this.props.link.linkTarget;
        if(this.state.linkTarget.length > 0 && !(this.state.linkTarget === oldLinkTarget)){
            this.updateData();
        } else {
            this.toggleChangeTargetMode()
        }
    }

    //TODO weitermachen
    updateData() {
        this.setState({isUpdating: true});
        const linkId = this.props.link.linkId;
        const createdAt = this.props.link.createdAt;
        const linkTarget = this.state.linkTarget;
        console.log("UPDATE ID: ", linkId);
        apiPut("link", "/link", linkId, linkTarget, createdAt)
            .then((response) => {
            console.log("Antwort: ", response);
            if(response.Error){
                console.log("Fehler: ", response.Error);
                this.setState({isUpdating: false});
            }else {
            this.setState({isUpdating: false});
            this.updateLinksInParent(response.aktualisierterEintrag);
            this.toggleChangeTargetMode();
            }})
            .catch((error) => {
                this.setState({isUpdating: false});
                console.log("Error: ", error);
                })
        }
    
    updateLinksInParent(updatedRow) {
        this.props.updateLinkList(updatedRow);
    }

    renderPlainRow(){
        return(
            <Card body style={{marginBottom: '10px'}}>
                <CardSubtitle>QR-Code:</CardSubtitle>
                <CardText>https://<b>{this.props.link.linkId}</b>.8qr.eu</CardText>
                <CardSubtitle>Linkziel:</CardSubtitle>
                <CardText>{this.state.linkTarget}</CardText>
                <div style={{textAlign: 'right'}}>
                    <Button onClick={this.toggleChangeTargetMode.bind(this)} outline color="primary">Linkziel ändern</Button>
                </div>
            </Card>
            )
    }

    renderChangeTargetRow(){
        return(
            <Card body style={{marginBottom: '10px'}}>
                <CardSubtitle>QR-Code:</CardSubtitle>
                <CardText>https://<b>{this.props.link.linkId}</b>.8qr.eu</CardText>
                <CardSubtitle>Linkziel:</CardSubtitle>
                <Input type="text" id="linkTarget" onChange={this.handleChange} value={this.state.linkTarget} />
                <div style={{textAlign: 'right', paddingTop: '5px'}}>
                <LoaderButton onClick={this.saveChangeHandler.bind(this)} color="success"
                    text="Änderung speichern" loadingText="wird gespeichert.." isLoading={this.state.isUpdating} />
                </div>
            </Card>
             )
    }

    render(){
        return(
            <Fragment>
                {this.state.isChangeMode ? this.renderChangeTargetRow() : this.renderPlainRow()}
            </Fragment>
            )
    }
    
}