import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import LoaderButton from "../../components/UI/LoaderButton";
import "./ResetPasswordMainComponent.css";
import { Form,FormGroup, Label, Input, FormText } from 'reactstrap';
import { translate } from '../../libs/translateErrors';

export default class ResetPasswordMainComponent extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        code: "",
        email: "",
        password: "",
        codeSent: false,
        confirmed: false,
        confirmPassword: "",
        isConfirming: false,
        isSendingCode: false
      };
    }

    componentDidMount(){
      window.scrollTo(0, 0);
  }
  
    validateCodeForm() {
      return this.state.email.length > 0;
    }
  
    validateResetForm() {
      return (
        this.state.code.length > 0 &&
        this.state.password.length > 0 &&
        this.state.password === this.state.confirmPassword
      );
    }
  
    handleChange = event => {
      this.setState({
        [event.target.id]: event.target.value
      });
    };
  
    handleSendCodeClick = async event => {
      event.preventDefault();  
      this.setState({ isSendingCode: true });
  
      try {
        await Auth.forgotPassword(this.state.email);
        this.setState({ codeSent: true });
      } catch (e) {
        alert(translate(e));
        this.setState({ isSendingCode: false });
      }
    };
  
    handleConfirmClick = async event => {
      event.preventDefault();
        
      this.setState({ isConfirming: true });
  
      try {
        await Auth.forgotPasswordSubmit(
          this.state.email,
          this.state.code,
          this.state.password
        );
        this.setState({ confirmed: true });
      } catch (e) {
        alert(translate(e));
        this.setState({ isConfirming: false });
      }
    };
  
    renderRequestCodeForm() {
        return (
        <Form>
          <FormGroup controlid="email">
            <Label>Email</Label>
            <Input
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              id="email"
            />
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            loadingText="Sendet Anfrage..."
            text="Passwort zurücksetzen"
            isLoading={this.state.isSendingCode}
            disabled={!this.validateCodeForm()}
            onClick={this.handleSendCodeClick}
            color="secondary"
          />
        </Form>
      );
    }
  
    renderConfirmationForm() {
      return (
          <Form>
            <FormGroup controlid="code">
            <Label>Bestätigungscode</Label>
            <Input
              autoFocus
              type="tel"
              value={this.state.code}
              onChange={this.handleChange}
              id="code"
            />
            <FormText>
              Bitte überprüfe deine Email ({this.state.email}) für den Bestätigungscode
            </FormText>
          </FormGroup>
          <hr />
          <FormGroup controlid="password">
            <Label>Neues Passwort</Label>
            <Input
              type="password"
              value={this.state.password}
              onChange={this.handleChange}
              id="password"
            />
            <FormText>
              Muss mind.8 Zeichen entahlten, Groß/Kleinschreibung und mind. eine Nummer
            </FormText>
          </FormGroup>
          <FormGroup controlid="confirmPassword">
            <Label>Passwort bestätigen</Label>
            <Input
              type="password"
              onChange={this.handleChange}
              value={this.state.confirmPassword}
              id="confirmPassword"
            />
          </FormGroup>
          <LoaderButton
            block
            type="submit"
            text="Bestätigen"
            loadingText="Bestätige..."
            isLoading={this.state.isConfirming}
            disabled={!this.validateResetForm()}
            onClick={this.handleConfirmClick}
            color="success"
          />
        </Form>
      );
    }
  
    renderSuccessMessage() {
      return (
        <div className="success">
          <p>Dein Passwort wurde zurückgesetzt.</p>
          <p>
            <Link to="/login">
              Klicke hier um dich einzuloggen.
            </Link>
          </p>
        </div>
      );
    }
  
    render() {
      return (
        <div className="ResetPassword">
          {!this.state.codeSent
            ? this.renderRequestCodeForm()
            : !this.state.confirmed
              ? this.renderConfirmationForm()
              : this.renderSuccessMessage()}
        </div>
      );
    }
  }