
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { Alert, Row, Col, Button } from 'reactstrap';
import config from '../../config';

export default class GoogleAnalytics extends Component{
    constructor(props){
        super(props);
        this.state = {
            cookieAlertIsVisible: false
        }
    }   

    componentDidMount() {
        console.log("ga-key aus .env: ", config.googleAnalytics.key);
        const gaKey = config.googleAnalytics.key;
        var disableStr = 'ga-disable-' + gaKey;
        if (!(document.cookie.indexOf(disableStr + '=true') === -1)) {
            //nicht-tracken-Cookie gefunden, nichts unternehmen
        } else if (!(document.cookie.indexOf('_ga') === -1)) {
            //Ga Cookie erlaubt existiert
            this.initializeGa();
        } else {
            //es exisitiert noch kein Cookie
            this.setState({cookieAlertIsVisible: true})
        }
    }    

    handletrackPageButtonClick(){
        this.initializeGa();
        this.setState({ cookieAlertIsVisible: false });    
      }
    
    handleDoNotTrackPageButtonClick(){
        this.setDisableCookie();
        this.setState({ cookieAlertIsVisible: false });
    }

    initializeGa(){
        const gaKey = config.googleAnalytics.key;
        console.log("initialize GA with key: ", gaKey)
        ReactGA.initialize(gaKey);
        ReactGA.set({ anonymizeIp: true });
        this.trackPage()
    }

    trackPage() {
        console.log("tracking with Ga..");
        ReactGA.set({ anonymizeIp: true });
        ReactGA.pageview(this.props.path);
    }
    
    setDisableCookie(){
        const gaKey = config.googleAnalytics.key
        var disableStr = 'ga-disable-' + gaKey;
        document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    }

    render(){
        //überflüssiges rendern vermeidern
        if(this.state.cookieAlertIsVisible){    
        return (
            <Alert color="light" isOpen={this.state.cookieAlertIsVisible} style={{ position: 'fixed', bottom: '0', left: '0', width: '100%', marginBottom: 0, zIndex: 110, backgroundColor: 'black', color: "white"}}>
                <p>Diese Webseite verwendet Cookies, um bestimmte Funktionen zu ermöglichen und unser Angebot zu verbessern.</p>
                <p>Mehr dazu in unserer <a href="/datenschutz" style={{ color: 'white', textDecoration: 'underline'}}>Datenschutzerklärung</a> - Sind Sie damit einverstanden?</p>
                <Row>
                <Col xs={{ size: '5', offset: 1 }}>
                <Button outline onClick={this.handleDoNotTrackPageButtonClick.bind(this)} color="danger" style={{width: '80%'}}>nein</Button>
                </Col>
                <Col xs={{ size: '5'}}>
                <Button outline onClick={this.handletrackPageButtonClick.bind(this)} color="success" style={{width: '80%'}}>ja</Button>
                </Col>
                </Row>
            </Alert>
        )
        } else {
            return null;
        }
    }
    
}