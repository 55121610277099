import React, {Component} from 'react';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import * as Sentry from '@sentry/browser';
import { I18n } from 'aws-amplify';
import { globalDictionary } from './libs/dictionary';


class EntryPoint extends Component{

    componentDidMount(){
       /*  Sentry.init({
            dsn: "https://b7ece13eb59c4b5cbbe5e1fb482bcd97@sentry.io/1311637",
            beforeSend(event) {
                // Modify the event here
                if (event.user) {
                    delete event.user.ip_address;
                }
                return event;
              }            
        }); */
        I18n.setLanguage('de');
        I18n.putVocabularies(globalDictionary);

    }

    render(){
        return(
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        )
    }
}

export default EntryPoint;
