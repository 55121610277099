import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import { apiGet } from '../../libs/callAPI';
import './RedirectMainComponent.css';
import { Row, Col } from 'reactstrap';
import Logo from '../../img/logo-stein-art.jpg';

export default class RedirectMainComponent extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getLinkTarget('redirect', `/redirect/${this.props.match.params.id}`);
  }

  getLinkTarget(apiName, apiPath) {
    apiGet(apiName, apiPath)
      .then((response) => {
        console.log('Antwort: ', response);
        if (response.Error) {
          console.log('Fehler: ', response.Error);
          this.props.history.push('/not-found');
        } else if (response.Target === null) {
          this.props.history.push('/not-found/');
        } else {
          setTimeout(() => {
            this.redirect(`https://${response.Target}`);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log('Error: ', error);
        this.props.history.push('/not-found');
      });
  }

  redirect(target) {
    console.log('leite weiter zu... --> ', target);
    window.location = target;
  }

  renderLoader() {
    return <ClipLoader sizeUnit={'px'} size={50} color={'#0000e9'} />;
  }

  render() {
    return (
      <Row className="flex-fill row--redirect">
        <Col className="col--redirect">
          {this.renderLoader()}
          <h2>Sie werden weitergeleitet...</h2>
        </Col>
        <Col className="col--redirect">
          <img
            src={Logo}
            alt="logo-stein-art"
            style={{ width: '10rem', marginBottom: '5rem' }}
          />
        </Col>
      </Row>
    );
  }
}
