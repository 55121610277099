import React, { Component, Fragment } from 'react';
import RedirectMainComponent from '../../components/Redirect/RedirectMainComponent';

export default class RedirectScreen extends Component{

    render(){
        return(
            <Fragment>
                <RedirectMainComponent {... this.props}/>
            </Fragment>
        )
    }
}