import React, { Component, Fragment } from 'react';
import AboutMainComponent from '../../components/About/AboutMainComponent';

export default class AboutScreen extends Component{

    render(){
        return(
            <Fragment>
                <AboutMainComponent {... this.props}/>
            </Fragment>
        )
    }
}