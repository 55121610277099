import React, { Component, Fragment } from "react";
import Lander from '../../components/Home/LanderComponent';
import MainViewHome from '../../components/Home/HomeComponent';
import { Col } from 'reactstrap';

export default class Home extends Component {

  componentDidMount(){
    window.scrollTo(0, 0);
}

renderLander() {
  return (
      <Lander />
  );
}

renderHome(){
  return (
      <MainViewHome {...this.props}/>
  )
}

render() {
  return (
    <Col>
      {this.props.isAuthenticated ? this.renderHome() : this.renderLander()}
    </Col>
  );
}
}