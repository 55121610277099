const configProd = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://llhsay2c4g.execute-api.eu-central-1.amazonaws.com/prod"
    },
  cognito: {
    REGION: "eu-central-1",
      USER_POOL_ID: "eu-central-1_03zLC28td",
      APP_CLIENT_ID: "3177h4nrj0gejmmelc1ekn20v8",
      IDENTITY_POOL_ID: "eu-central-1:6b7c6766-8c4e-4163-a805-25de337b4f79"
    },
  };

const configDev = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://2l7n7h9nhh.execute-api.eu-central-1.amazonaws.com/dev"
    },
  cognito: {
    REGION: "eu-central-1",
      USER_POOL_ID: "eu-central-1_TdiXPY1eD",
      APP_CLIENT_ID: "3rn9o40op3dbr2ld4654044l9c",
      IDENTITY_POOL_ID: "eu-central-1:6182d983-c6f5-4958-ab2d-9ffa1943420b"
    },
  };

const config = process.env.REACT_APP_STAGE === 'prod' ? configProd : configDev;

export default {
    googleAnalytics: {
      key: process.env.REACT_APP_GA_KEY
    },
  ...config 
};
