import React from "react";
import { Route, Switch } from "react-router-dom";
//import AuthenticatedRoute from "./components/Router/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/Router/UnauthenticatedRoute";
import AppliedRoute from "./components/Router/AppliedRoute";
import NotFound from "./screens/NotFound/NotFound";
import Home from "./screens/Home/Home";
import LoginScreen from './screens/Login/LoginScreen';
import ResetPasswordScreen from "./screens/ResetPassword/ResetPasswordScreen";
import ImpressumScreen from "./screens/Impressum/ImpressumScreen";
import DatenschutzScreen from "./screens/Datenschutz/DatenschutzScreen";
import AboutScreen from './screens/About/AboutScreen';
import AgbScreen from './screens/Agb/AgbScreen';
import RedirectScreen from './screens/Redirect/RedirectScreen';
import withNavbar from './components/Router/WithNavbar';


export default ({childProps}) =>
  <Switch>
    <AppliedRoute path="/" exact component={withNavbar(Home)} props={childProps} />
    <Route path="/datenschutz" exact component={withNavbar(DatenschutzScreen)}/>
    <Route path="/impressum" exact component={withNavbar(ImpressumScreen)}/>
    <Route path="/about" exact component={withNavbar(AboutScreen)} props={childProps} />
    <Route path="/agb" exact component={withNavbar(AgbScreen)} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={withNavbar(LoginScreen)}  props={childProps}/>
    <UnauthenticatedRoute path="/reset-password" exact component={withNavbar(ResetPasswordScreen)} props={childProps} />
    <Route path="/not-found" exact component={withNavbar(NotFound)} />
    <Route path="/:id" exact component={RedirectScreen} props={childProps} />


    { /* Finally, catch all unmatched routes */ }
    <Route component={withNavbar(NotFound)} />
  </Switch>;