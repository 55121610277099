import React, { Component } from 'react';
import {Navbar, NavbarBrand, Nav, NavItem, NavLink, Col, Row, Container} from 'reactstrap';
import { Link } from "react-router-dom";
import { Auth } from 'aws-amplify';
import './Navbar.css'


export default class NavigationBar extends Component {

    async handleLogoutClick(event){
        event.preventDefault();     
        await Auth.signOut();
        this.props.callbackToAppFromNavigationBar(); 
    }

    returnTitleForCurrentStage(){
        if(process.env.REACT_APP_STAGE === "prod"){
            return " QR"
        } else {
            return " QR - Test"
        }
    }

    renderNavbarUserIsLoggedIn(){
        return(
            <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink onClick={this.handleLogoutClick.bind(this)} tag={Link} to="/">Abmelden</NavLink>
                </NavItem>
            </Nav>
            )
    }

    renderNavbarUserIsNotLoggedIn(){
        return(
            <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink tag={Link} to="/login">Anmelden</NavLink>
                </NavItem>
            </Nav>
        )

    }

    render(){
        return(
            <Row className="row--navbar"> 
                <Col>
                <Container>
                    <Navbar light>
                        <NavbarBrand tag={Link} to="/">&#8734;{this.returnTitleForCurrentStage()}</NavbarBrand>
                        {this.props.isAuthenticated ? this.renderNavbarUserIsLoggedIn() : this.renderNavbarUserIsNotLoggedIn()}
                    </Navbar>
            </Container>
                </Col>
            </Row>
        )
    }
}