import React, { Component, Fragment } from 'react';
import { Form, FormGroup, Label, Input, FormFeedback, FormText } from 'reactstrap';
import LoaderButton from '../UI/LoaderButton';
import PasswordValidator from 'password-validator';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';


export default class CompleteNewPasswordMainComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            confirmPassword: "",
            confirmPasswordIsInvalid: false,
            PasswordIsValid: null,
            agbCheckboxChecked: false,
            abgCheckboxIsInvalid: false
        }
    }

    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    toggleAgbCheckbox(agbCheckboxChecked){
        console.log(agbCheckboxChecked);
        this.setState({agbCheckboxChecked: agbCheckboxChecked});
        if(agbCheckboxChecked === true ) {
            this.setState({abgCheckboxIsInvalid: false})
        }
        
    }

    validateForm() {
        return (
          this.state.password.length > 0 &&
          this.state.confirmPassword.length > 0 &&
          this.state.PasswordIsValid
        );
      }
    
    handleOnBlurPassword(){
        if(this.validatePassword(this.state.password)){
        this.setState({PasswordIsValid: true, PasswordIsInValid: false})
      }else {
        this.setState({PasswordIsInValid: true, PasswordIsValid: false})
      }
    }

    validatePassword(password){
      const passwordSchema = new PasswordValidator();
      passwordSchema.is().min(8).has().uppercase().has().lowercase().has().digits();
      return passwordSchema.validate(password);
    }
    
    checkPasswordEquality(event){
        event.preventDefault();    
        
        if(this.state.password === this.state.confirmPassword){
            this.setState({confirmPasswordIsInvalid: false})
            this.checkAgbCheckbox()
        } else {
            this.setState({confirmPasswordIsInvalid: true})
        }
    }

    checkAgbCheckbox(){
        if(this.state.agbCheckboxChecked){
            this.handleSubmit();
        } else {
            this.setState({abgCheckboxIsInvalid: true})
        }
    }
    
    handleSubmit(){
        Auth.completeNewPassword(this.props.user, this.state.password)
            .then(user => {
                console.log(user);
            })
            .catch(e => {
                console.log(e);
            });
        this.props.userHasAuthenticated(true);
        this.props.history.push("/");
    }
    
    render() {
        return (
            <Fragment>
                    <br/>
                    <Form className="form--login">
                        <FormGroup controlid="password" style={{marginBottom:'0px'}}>
                            <Label>Neues Passwort</Label>
                            <Input
                                valid={this.state.PasswordIsValid}
                                invalid={this.state.PasswordIsInValid}
                                value={this.state.password}
                                onChange={this.handleChange}
                                onBlur={this.handleOnBlurPassword.bind(this)}
                                type="password"
                                id="password"
                            />
                                <FormFeedback valid>
                                Passwort entspricht den Anforderungen
                                </FormFeedback>
                                <FormFeedback>
                                Passwörter entspricht nicht den Anforderungen
                                </FormFeedback>
                        </FormGroup>
                        <FormText>
                        min. acht Zeichen, eine Zahl und Groß / Kleinschreibung
                        </FormText>
                        <FormGroup controlid="confirmPassword">
                            <Label>Passwort bestätigen</Label>
                            <Input
                                invalid={this.state.confirmPasswordIsInvalid}
                                value={this.state.confirmPassword}
                                onChange={this.handleChange}
                                type="password"
                                id="confirmPassword"
                                />
                                <FormFeedback>
                                Passwörter stimmen nicht überein
                                </FormFeedback>
                        </FormGroup>
                        <FormGroup check>
                            <Input type="checkbox" id="agbCheckbox" invalid={this.state.abgCheckboxIsInvalid}
                                checked={this.state.agbCheckboxChecked} onChange={this.toggleAgbCheckbox.bind(this, !this.state.agbCheckboxChecked)}/>
                            <Label for="agbCheckbox" check>
                            Mit dem Festlegen meines Passwortes beende ich die Registrierung bei 8QR und stimme den <Link to="/agb">AGBs</Link> zu.
                            </Label>
                            <FormFeedback>
                                Um unsere Plattform nutzen zu können, müssen sie unseren AGBs zustimmen.
                            </FormFeedback>
                        </FormGroup>
                        <br />
                        <LoaderButton
                        block             
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Passwort speichern"
                        loadingText="Passwort wird gespeichert..."
                        onClick={this.checkPasswordEquality.bind(this)}
                        color="success"
                        />
                    </Form>
                </Fragment>
        );
    }
}
