import React, { Component, Fragment } from 'react';
import AgbMainComponent from '../../components/Agb/AgbMainComponent';

export default class AgbScreen extends Component{

    render(){
        return(
            <Fragment>
                <AgbMainComponent {... this.props}/>
            </Fragment>
        )
    }
}