import React, { Component, Fragment } from 'react';
import './App.css';
import { withRouter } from "react-router-dom";
import { Container, Row} from 'reactstrap';
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import Footer from './components/Footer/Footer';
import NavigationBar from './components/Navbar/Navbar';
import GoogleAnalytics from './components/GoogleAnalytics/GoogleAnalytics';
import { ClipLoader } from 'react-spinners';



class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
    };
  }

  async componentDidMount() {
    this.checkCurrentSession();
    console.log("STAGE: " + process.env.REACT_APP_STAGE);
  }
  
  async checkCurrentSession(){
      try {
      if (await Auth.currentSession()) {
        this.userHasAuthenticated(true);
      }
    }
    catch(error) {
      if (error !== 'No current user') {
        alert("Problem beim Login: Bitte lade die Seite neu");        
      }
    }
    this.setState({ isAuthenticating: false});
  }
  
  userHasAuthenticated = async (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  }
  
  renderGoogleAnalytics(){
    return <GoogleAnalytics path="/home" />
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      callbackToAppFromNavigationBar: this.userHasAuthenticated.bind(this, false)
      };

    //rendern wenn Authentifizierung geklärt ist
    if(this.state.isAuthenticating === false ){
      return (
          <Fragment>
            {/*this.renderGoogleAnalytics()*/}
            <Container fluid className="d-flex flex-column">
                   <Routes childProps={childProps} />
                   <Footer />
            </Container>
          </Fragment>
        );
      }else {
        return (
            <div className="loader-wrapper">
              <ClipLoader
              sizeUnit={"px"}
              size={50}
              color={'#28a745'}
            />
          </div>
          );
        }
    }
  }
          
export default withRouter(App);
