import React, { Component } from 'react';

export default class DatenschutzMainComponent extends Component {

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <h1>Datenschutz</h1>
        )
    }

}