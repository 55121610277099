import React, { Component, Fragment } from "react";
import { FormGroup, Form, Label, Input } from "reactstrap";
import "./LoginMainComponent.css";
import { Auth } from "aws-amplify";
import LoaderButton from "../../components/UI/LoaderButton";
import { translate } from '../../libs/translateErrors';
import { Link } from "react-router-dom";


export default class LoginMainComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
    };
  }

  componentDidMount(){
    window.scrollTo(0, 0);
}
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.type]: event.target.value
    });
  }

  handleSubmit = async event => {
      event.preventDefault();      
      this.setState({isLoading: true});
      Auth.signIn(this.state.email, this.state.password)
        .then((user) => {
          console.log(user);
          this.setState({isLoading: false});
          if(user.challengeName === 'NEW_PASSWORD_REQUIRED'){
            console.log("..!Passwort ändern");
            this.props.userNeedsToCompletePassword(user);
          } else {
            this.props.userHasAuthenticated(true);
            this.props.history.push("/");
          }
        })
        .catch((error) => {
          window.alert(translate(error));
          this.setState({isLoading: false})
        })
    }  

  render() {
    return (
      <Fragment>
        <br/>
        <Form onSubmit={this.handleSubmit} className="form--login">
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup className="form-group--password">
            <Label>Passwort</Label>
            <Input
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Link to="/reset-password">Passwort vergessen?</Link>
          <br />
          <LoaderButton
            className="LoaderButton--login"
            block
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Einloggen"
            loadingText="Loggt ein…"
            color="success"
          />
        </Form>
      </Fragment>
    );
  }
}