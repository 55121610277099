import React, { Component, Fragment } from 'react';
import { Row, Col, Container } from 'reactstrap';
import NavigationBar from './../Navbar/Navbar';
import './WithNavbar.css';


const withNavbar = (CildComponent)  => {
    class withNavbar extends Component {
        
            render(){
                return(
                    <Fragment>
                            <NavigationBar {... this.props} />
                        <Row className="flex-fill row--content">
                            <Col>
                            <Container>
                            <CildComponent {... this.props} />
                            </Container>
                            </Col>
                        </Row>
                    </Fragment>
                    )
        }
    }
    return withNavbar;
}

export default withNavbar;