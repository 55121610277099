import React, { Component, Fragment } from "react";
import ResetPasswordMainComponent from './../../components/ResetPassword/ResetPasswordMainComponent';

export default class ResetPasswordScreen extends Component {

  render(){
    return(
      <Fragment>
        <ResetPasswordMainComponent {... this.props} />
      </Fragment>
    )
  }
  }