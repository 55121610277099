import React, { Component } from 'react';
import './LanderComponent.css'


export default class Lander extends Component {

    render(){

        return(
            <div className="lander">
                <h1>&#8734; QR</h1>
                <br />
                <p>dynamische QR-Codes in Stein</p>
            </div>
        )
    }
}
