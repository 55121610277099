import { API } from 'aws-amplify';

export async function apiGet(apiName, apiEndpoint, lastEvaluatedKey){
    try{
        //setzt URL Parameter für den API Aufruf, falls nicht alle Daten geholt wurden
        if(lastEvaluatedKey){
                return await API.get(apiName, apiEndpoint, {
                    'queryStringParameters': lastEvaluatedKey
                });
            } else {
                return await API.get(apiName, apiEndpoint);
            }
    } catch (error) {
        return {Error: error}
        }
}

export async function apiPut(apiName, apiEndpoint, linkId, linkTarget, createdAt){
    try{
        return await API.put(apiName, `${apiEndpoint}/${linkId}`, {
            body: {
                linkTarget: linkTarget,
                createdAt: createdAt
            }
        })
    } catch (error) {
        return {Error: error}
        }
}