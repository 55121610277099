import React, { Component } from 'react';

export default class AboutMainComponent extends Component{

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render(){
        return(
            <h1>AGB</h1>
        )
    }
}
